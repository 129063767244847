import React from "react"

const Spacer = props => {
  return (
    <div style={{
      width: props.x,
      height: props.y,
      display: props.inline ? "inline-block" : "block"
    }} />
  )
}

Spacer.defaultProps = {
  x: 0,
  y: 0,
  inline: false
}

export default Spacer