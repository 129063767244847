import React from "react"
import Image from "gatsby-image"
import { Link, graphql } from "gatsby"

import colors from "../../content/tags.json"

const TagStrip = props => {
  const tags = props.tags.map((tag) => {
    const t = colors[tag] || colors["default"] || { color: "#ccc" }
    return <Link style={{fontSize: 10, fontWeight: "bold", color: "white", padding: 7, marginRight: 5, backgroundColor: t.color, borderRadius: 5}} to={`/tags/${tag}`}>{tag}</Link>
  })

  return (
    <div>{tags}</div>
  )
}

TagStrip.defaultProps = {
  tags: []
}

export default TagStrip